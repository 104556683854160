@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Space Grotesk", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border-color: #fff;
  color: #fff;
  display: block;
  /* margin: 50px auto 0; */
  background-color: transparent;
  border: 1px solid;
  border-radius: 28px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0.05em;
  line-height: 24px;
  padding: 8px 32px;
  text-transform: uppercase;
  box-sizing: border-box;
  margin: 4px;
}

.editor {
  width: 50%;
  position: fixed;
  right: 0;
}